import api from '@/api/api';

const state = {
  token: localStorage.getItem('user-token') || ''
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status
};

/**
 * Мутации
 */
const mutations = {
  /**
   * Авторизация успешна
   * @param state
   * @param {Object} data
   * @property {Object} data.staff_profile
   * @property {String} data.staff_profile.auth_key
   * @constructor
   */
  AUTH_SUCCESS: (state, data) => {
    state.token = data.user.auth_key;
    localStorage.setItem('user-token', data.user.auth_key);
    api.defaults.headers.Authorization = `Bearer ${data.user.auth_key}`;
  },

  /**
   * Очистка токенов авторизации
   * @param state
   * @constructor
   */
  AUTH_CLEAR: (state) => {
    localStorage.removeItem('user-token');
    localStorage.removeItem('vuex');
    state.token = '';
  }
};

const actions = {
  /**
   * Запрос на авторизацию
   *
   * @param commit
   * @param user
   *
   * @typedef  {Object} response.data               Данные возвращенные после попытки авторизации
   * @property {Object} response.data.app_menu      Главное меню приложения
   * @property {Object} response.data.app_config    Конфигурация приложения
   * @property {Object} response.data.staff_profile Профиль оператора
   *
   * @returns {Promise}
   */
  authRequest: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      console.log('authRequest', user);
      api
        .post('site/login', {
          login: user.username,
          password: user.password
        })

        .then((response) => {
          console.log('auth data', response.data);
          if (response.data.type === 'success' && response.data.user.auth_key !== null) {
            commit('SET_USER_PROFILE', response.data.user);
            commit('SET_APP_MENU', response.data.app_menu);
            commit('SET_APP_CONFIG', response.data.app_config);
            commit('AUTH_SUCCESS', response.data);
            resolve(response);
          } else {
            commit('AUTH_CLEAR');
            resolve(response);
          }
        })

        .catch(function (error) {
          commit('AUTH_CLEAR');
          console.log(error);
          reject(error);
        });
    });
  },

  /**
   * Logout
   *
   * @param commit
   * @returns {Promise<unknown>}
   * @constructor
   */
  authLogout: ({ commit }) => {
    return new Promise((resolve) => {
      commit('AUTH_CLEAR');
      commit('CLEAR_APP_MENU');
      commit('CLEAR_APP_CONFIG');
      commit('CLEAR_STAFF_PROFILE');
      resolve();
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
