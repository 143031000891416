<template lang="pug">
  v-dialog(v-model="visible" width="20%")
    v-card(tile)
      v-toolbar.primary(flat)
        v-toolbar-title Удалить тикет?
        v-spacer
        v-btn(icon text @click="close()")
          v-icon mdi-close
      v-card-actions
        v-btn.accent(@click="deleteTicket()" depressed) Удалить


</template>

<script>
/**
 * Диалоговое окно удаления тикета
 */

import api from '@/api/api';

export default {
  /**
   * @typedef   {Function} data        Данные  компонента
   * @returning {Object}
   * @property  {Boolean}  visible     Видимость диалогового окна
   * @property  {Number}   ticketId    ID тикета
   */
  data() {
    return {
      visible: false,
      ticketId: null
    };
  },

  methods: {
    /**
     * Отрывает диалоговое окно"
     *
     * @param {Number} ticketId
     */
    open: function (ticketId) {
      this.ticketId = ticketId;
      this.visible = true;
    },

    /**
     * Закрывает диалоговое окно
     */
    close() {
      this.ticketId = null;
      this.visible = false;
    },

    deleteTicket() {
      api
        .delete(`/order/tickets/${this.ticketId}`, {})
        .then(() => {
          this.processSuccess('Тикет удален');
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при удалении тикета');
        });
    },

    processSuccess(title) {
      this.$bus.$emit('reloadTickets');
      this.$notify({
        type: 'success',
        title: title
      });
      this.close();
    }
  }
};
</script>
