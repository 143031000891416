<template lang="pug">
  v-row.mx-0.my-2(id='goods-update')
    v-col(sm="6")
      v-card(flat outlined)
        v-toolbar(flat dense)
          v-toolbar-title Сайты
          v-spacer
          v-btn(@click ="$refs.SiteUpdateDialog.open()" icon)
            v-icon mdi-plus
        table.table.primary-bg
          tr
            th
            th
            th Название сайта
            th url

          template(v-for="site in sites")
            tr
              td.actions
                v-icon(v-if="site.is_active") mdi-check
                v-icon(v-else) mdi-close

              td.actions

                v-btn(@click ="$refs.SiteUpdateDialog.open(site)" icon)
                  v-icon mdi-playlist-edit

                v-btn(:href="site.url" target="_blank" icon)
                  v-icon mdi-open-in-new

                v-btn(:href="site.url" target="_blank" icon)
                  v-icon mdi-plus
              td
                div {{site.title}}
                div.caption {{site.comment}}

              td {{site.url}}


    site-update-dialog(ref="SiteUpdateDialog")
    site-delete-dialog(ref="SiteDeleteDialog")

</template>
<script>
import api from '@/api/api';
import SiteUpdateDialog from './dialogs/SiteUpdate';
import SiteDeleteDialog from './dialogs/SiteDelete';

export default {
  data() {
    return {
      sites: []
    };
  },

  components: {
    SiteUpdateDialog,
    SiteDeleteDialog
  },

  created() {
    this.getSites();

    this.$bus.$on('siteChanged', () => {
      this.getSites();
    });
  },

  beforeDestroy() {
    this.$bus.$off('siteChanged');
  },

  methods: {
    /**
     * Список сайтов доноров
     */
    getSites() {
      api
        .get('/sites', {
          params: {
            'per-page': 0,
            sort: '-id'
          }
        })
        .then((response) => {
          this.sites = response.data;
        })
        .catch((e) => {
          console.log('error', e);
        });
    }
  }
};
</script>
