import axios from 'axios';
import { store } from '@/store';

const api = axios.create({
  // baseURL: 'http://admin.api'
  baseURL: 'https://admin-api.delicious-meal.com'
});
var token = localStorage.getItem('user-token');
api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

api.interceptors.request.use(
  (config) => {
    // store.dispatch('progressBarEnable').then();
    store.dispatch('spinnerEnable').then();
    return config;
  },
  (error) => {
    console.log(error.response);
    // store.dispatch('progressBarDisable').then();
    store.dispatch('spinnerDisable').then();
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // store.dispatch('progressBarDisable').then();
    store.dispatch('spinnerDisable').then();
    return response;
  },
  (error) => {
    console.log(error.response ?? error.message);
    // store.dispatch('progressBarDisable').then();
    store.dispatch('spinnerDisable').then();
    return Promise.reject(error);
  }
);

export default api;
