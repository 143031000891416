<template lang="pug">
  div(id='create-tree-config')
    v-dialog(v-model="visible" width="600px" persistent)
      v-card(tile)
        v-toolbar(flat)
          v-toolbar-title Добавить страницу
          v-spacer
          v-btn(icon text @click="visible = false")
            v-icon.accent--text mdi-close

        v-card-text

          v-text-field(
            class="mb-4"
            v-model="url"
            placeholder="URL"
            outlined
            hide-details
            :error-messages="formErrors.title"
          )

          v-checkbox.mr-4(
            color="accent"
            label="Обработать данные"
            hide-details
            v-model="processData"
            true-value=true
            false-value=false
          )
        v-card-actions.primary
          v-btn.primary(@click="create" depressed) Сохранить

</template>
<script>
import nocApi from '@/api/api';

export default {
  data() {
    return {
      url: null,
      siteId: null,
      formErrors: {},
      visible: false,
      processData: true
    };
  },

  methods: {
    open(siteId) {
      this.siteId = siteId;
      this.visible = true;
    },

    close() {
      this.formErrors = {};
      this.visible = false;
    },

    create() {
      nocApi
        .post('/site-pages/create', {
          site_id: this.siteId,
          url: this.url,
          process_data: this.processData
        })
        .then(() => {
          this.processSuccess('Страница добавлена');
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при добавлении страницы');
        });
    },

    processSuccess(title) {
      this.$bus.$emit('pageChanged');
      this.$notify({
        type: 'success',
        title: title
      });
      this.close();
    }
  }
};
</script>
