<template lang="pug">
  div(id='create-tree-config')
    v-dialog(v-model="visible" persistent)
      v-card(tile)
        v-toolbar(flat)
          v-toolbar-title Микроданные
          v-spacer
          v-btn(icon text @click="visible = false")
            v-icon.accent--text mdi-close

        v-card-text
          pre {{microdata}}

</template>
<script>
import nocApi from '@/api/api';

export default {
  data() {
    return {
      microdata: null,
      visible: false
    };
  },

  methods: {
    open(id) {
      this.getMicrodata(id);
      this.visible = true;
    },

    close() {
      this.visible = false;
    },

    getMicrodata(id) {
      nocApi
        .get('/site-pages/get-microdata', {
          params: {
            id: id
          }
        })
        .then((response) => {
          this.microdata = response.data;
          console.log(this.microdata);
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при чтении микроданных');
        });
    }
  }
};
</script>
