<template lang="pug">
  div(id="site-pages")
    v-toolbar(
      class="page-bar"
      flat
    )
      v-autocomplete(
        class="mr-2 shrink"
        hide-details
        outlined
        placeholder="Все сайты"
        clearable
        :items="sites"
        item-text="title"
        item-value="id"
        v-model="filter.site_id"
      )

    v-card(tile flat outlined)
      v-toolbar(v-if="filter.site_id" flat)
        v-btn.mr-3.primary(@click="$refs.PageCreate.open(filter.site_id)" depressed) Добавить страницу
        v-toolbar-title {{site.title}}

      v-toolbar(v-else flat)
        v-toolbar-title Все страницы

      template(v-if="pages.length > 0")

          table.table.primary-bg
            tr
              th URL
              th Название
            template(v-for="page in pages")
              tr
                td {{page.id}}
                td {{page.url}}
                td
                  v-btn(@click ="loadHtml(page.id)" depressed) Загрузить HTML
                td
                  v-btn(@click ="pageProcess(page.id)" depressed) Процесс
                td
                  v-btn(@click ="$refs.MicrodataView.open(page.id)" depressed) Микроданные
    page-create(ref="PageCreate")
    microdata-view(ref="MicrodataView")


</template>

<script>
import api from '@/api/api';
import PageCreate from '@/components/Sources/Pages/dialogs/PageCreate';
import MicrodataView from '@/components/Sources/Pages/dialogs/MicrodataView';

/**
 * @property   {Object}      filter               Фильтры для поиска
 * @property   {Number}      filter.site_id       Сайт
 *
 */
export default {
  data() {
    return {
      sites: [],
      site: {},
      pages: [],
      filter: {}
    };
  },

  components: {
    PageCreate,
    MicrodataView
  },

  created() {
    this.getSites();
    this.getPages();
  },

  watch: {
    'filter.site_id'() {
      this.getPages();
    }
  },

  methods: {
    /**
     * Список сайтов
     */
    getSites() {
      api
        .get('/sites', {
          params: {
            'per-page': 0
          }
        })
        .then((response) => {
          this.sites = response.data;
        });
    },

    /**
     * Список страниц сайта донора
     */
    getPages() {
      api
        .get('/site-pages', {
          params: {
            filter: this.filter,
            'per-page': 0
          }
        })
        .then((response) => {
          this.pages = response.data;
        })
        .catch((e) => {
          console.log('error', e);
        });
    },

    /**
     * Список сайтов
     */
    pageProcess(id) {
      api
        .get('/site-pages/process', {
          params: {
            id: id
          }
        })
        .then(() => {
          this.processSuccess('Страница обработана');
        });
    },

    /**
     * Загрузить HTML страницы
     */
    loadHtml(id) {
      api
        .get('/site-pages/load-html', {
          params: {
            id: id
          }
        })
        .then(() => {
          this.processSuccess('HTML загружен');
        });
    },

    processSuccess(title) {
      this.$notify({
        type: 'success',
        title: title
      });
    }
  }
};
</script>
