<template lang="pug">
  div(id="side-menu" class="transparent")
    v-toolbar.accent.white--text(flat height="40px")
      span delicious-meals.com
    v-list.pt-0(flat)
      template(v-for="(item, index) in appMenu")
        v-list-item(
          class="side-menu-item"
          :to="{name: item.route}"
          active-class="primary accent--text"
          :key="index"
        )
          v-list-item-action.icon
            v-icon.fg--text {{item.icon}}
          v-list-item-content
            v-list-item-title.fg--text.font-weight-bold {{item.title}}
        v-divider


</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      menu: []
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      appMenu: 'getAppMenu'
    })
  }
};
</script>

<style lang="scss" scoped>
#side-menu {
  /*border-right: #bababa 1px solid;*/
}

.icon {
  margin-right: 15px !important;
}
.side-menu-item {
  height: 39px;
}
</style>
