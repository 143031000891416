<template lang="pug">
  v-container(fluid fill-height)
    v-row.pt-5(justify="center")
      v-col(xs="12" sm="8" md="4" lg="3")
        v-card.primary(flat tile outlined)
          v-toolbar.lighten(flat)
            v-toolbar-title Авторизация
          v-divider
          v-card-text
            v-form(id="login-form" @submit.prevent="login")
              v-text-field.mt-5.mb-5(
                label="Логин"
                v-model="username"
                :error-messages="formErrors.login"
                outlined
                hide-details
              )
              v-text-field.mt-4.mb-5(
                label="Пароль"
                type="password"
                v-model="password"
                :error-messages="formErrors.password"
                outlined
                hide-details
              )
          v-divider
          v-card-actions
            v-btn(
              type="submit"
              form="login-form"
              color="lighten"
              depressed
            )
              v-icon(left) mdi-login-variant
              span Войти
</template>

<script>
export default {
  data() {
    return {
      password: '',
      formErrors: {}
    };
  },
  methods: {
    login() {
      const { username, password } = this;
      this.formErrors = {};
      console.log('authRequest', username, password);
      this.$store
        .dispatch('authRequest', { username, password })
        .then((response) => {
          console.log('login');
          if (response.data.type === 'success') {
            console.log('login success');
            // this.$store.dispatch('loadStreets');
            this.$notify(response.data);
            this.$router.push({ name: 'Home' });
          }
          if (response.data.type === 'error') {
            this.formErrors = response.data.errors;
            this.$notify(response.data);
          }
        })
        .catch(function (e) {
          console.log('error', e);
        });
    }
  }
};
</script>
