import Vue from 'vue';
import App from './App.vue';

/**
 * Vue router
 */
import router from './router';

/**
 * Vuex
 */
import { store } from './store';

/**
 * Vuetify
 */
import vuetify from './plugins/vuetify';

/**
 * CKEditor
 */
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

/**
 * Уведомления
 */
import Notifications from 'vue-notification';
Vue.use(Notifications);

/**
 * Шина обработки событий
 */
Vue.prototype.$bus = new Vue();

/**
 * Lodash
 */
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
Vue.use(VueLodash, { lodash: lodash });

/**
 * Общие миксины
 */
import { commonMixins } from '@/components/mixins/CommonMixins';
Vue.mixin(commonMixins);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
