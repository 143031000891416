import api from '@/api/api';

/**
 * @typedef {Object}   state                             Сохраненные состояния приложения
 * @property {Boolean} state.spinner                     Включить/выключить индикатор сетевой активности
 * @property {Object}  state.ticketFilters               Фильтры отображения списка тикетов
 * @property {Number}  state.ticketFilters.departmentId  Для какого отдела показать тикеты
 * @property {Boolean} state.ticketFilters.showExpired   Показывать просроченные
 * @property {String}  state.ticketFilters.date          Рабочая дата тикетов
 */
const state = {
  appMenu: [],
  appConfig: {},
  userProfile: null,
  spinner: false
};

/**
 * Геттеры
 *
 */
const getters = {
  /**
   * Состояние индикатора сетевой активности
   * @param state
   * @returns {boolean}
   */
  getSpinnerState: (state) => {
    return state.spinner;
  },

  /**
   * Меню приложения
   * @param state
   */
  getAppMenu: (state) => {
    return state.appMenu;
  },

  /**
   * Меню модуля приложения
   * @param state
   */
  getAppSubMenu: (state) => {
    return state.appSubMenu;
  },

  /**
   * Конфигурация приложения
   * @param state
   */
  getAppConfig: (state) => {
    return state.appConfig;
  },

  /**
   * Профиль оператора
   * @param state
   */
  getUserProfile: (state) => {
    return state.staffProfile;
  }
};

/**
 * Мутации
 *
 */
const mutations = {
  /**
   * Включить спиннер
   *
   * @param state
   * @constructor
   */
  SPINNER_ENABLE: (state) => {
    state.spinner = true;
  },

  /**
   * Выключить спиннер
   *
   * @param state
   * @constructor
   */
  SPINNER_DISABLE: (state) => {
    state.spinner = false;
  },

  /**
   * Главное меню приложения (установка)
   * @param state
   * @param appMenu
   * @constructor
   */
  SET_APP_MENU: (state, appMenu) => {
    state.appMenu = appMenu;
  },

  /**
   * Главное меню приложения (очистка)
   *
   * @param state
   * @constructor
   */
  CLEAR_APP_MENU: (state) => {
    state.appMenu = [];
  },

  /**
   * Конфигурация приложения (установка)
   * @param state
   * @param appConfig
   * @constructor
   */
  SET_APP_CONFIG: (state, appConfig) => {
    state.appConfig = appConfig;
  },

  /**
   * Конфигурация приложения (очистка)
   * @param state
   * @constructor
   */
  CLEAR_APP_CONFIG: (state) => {
    state.appConfig = [];
  },

  /**
   * Профиль оператора (установка)
   * @param state
   * @param staffProfile
   * @constructor
   */
  SET_USER_PROFILE: (state, userProfile) => {
    state.userProfile = userProfile;
  },

  /**
   * Профиль оператора (очистка)
   * @param state
   * @constructor
   */
  CLEAR_USER_PROFILE: (state) => {
    state.userProfile = null;
  }
};

/**
 * Действия
 *
 */
const actions = {
  /**
   * Включить спиннер
   *
   * @param context
   * @returns {Promise<void>}
   */
  spinnerEnable: async (context) => {
    context.commit('SPINNER_ENABLE');
  },

  /**
   * Выключить спиннер
   *
   * @param context
   * @returns {Promise<void>}
   */
  spinnerDisable: async (context) => {
    context.commit('SPINNER_DISABLE');
  },

  /**
   * Загружает список городских районов
   *
   * @param context
   * @returns {Promise<void>}
   */
  loadAppMenu: async (context) => {
    let { data } = await api.get('/site/app-menu', {});
    context.commit('SET_APP_MENU', data);
  },

  /**
   * Загружает список городских районов
   *
   * @param context
   * @returns {Promise<void>}
   */
  loadAppConfig: async (context) => {
    let { data } = await api.get('/site/app-config', {});
    context.commit('SET_APP_CONFIG', data);
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
