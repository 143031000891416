export const commonMixins = {
  methods: {
    processError(error, title) {
      let text = '';
      if (Array.isArray(error.response.data)) {
        title = title === undefined ? 'Ошибка' : title;
        this.formErrors = error.response.data;
        error.response?.data.forEach((element) => {
          this.formErrors[element.field] = element.message;
          text += element.message + '<br>';
        });
      } else {
        title = error.name;
        text = error.message;
      }
      this.$notify({
        type: 'error',
        title: title,
        text: text
      });
      console.log(error);
    },

    /**
     * Проверяет есть ли у авторизованного пользователя определённое разрешение
     */
    hasPermission(p) {
      let hasPermission = false;
      if (this.$store.getters.getStaffProfile !== null) {
        for (let permission of this.$store.getters.getStaffProfile.permissions) {
          if (permission === p || permission === 'root') {
            hasPermission = true;
            break;
          }
        }
      }
      return hasPermission;
    },

    isEmptyObject(obj) {
      return JSON.stringify(obj) === '{}';
    }
  }
};
