<template lang="pug">
  v-dialog(v-model="visible" width="20%")
    v-card(tile)
      v-toolbar.primary(flat)
        v-toolbar-title Удалить рецепт?
        v-spacer
        v-btn(icon text @click="close()")
          v-icon mdi-close
      v-card-actions
        v-btn.accent(@click="deleteTicket()" depressed) Удалить


</template>

<script>
/**
 * Диалоговое окно удаления тикета
 */

import api from '@/api/api';

export default {
  /**
   * @typedef   {Function} data        Данные компонента
   * @returning {Object}
   * @property  {Boolean}  visible     Видимость диалогового окна
   * @property  {Number}   recipeId    ID рецепта
   */
  data() {
    return {
      visible: false,
      recipeId: null
    };
  },

  methods: {
    /**
     * Отрывает диалоговое окно"
     *
     * @param {Number} recipeId
     */
    open: function (recipeId) {
      this.recipeId = recipeId;
      this.visible = true;
    },

    /**
     * Закрывает диалоговое окно
     */
    close() {
      this.recipeId = null;
      this.visible = false;
    },

    deleteTicket() {
      api
        .delete(`/recipes/${this.recipeId}`, {})
        .then(() => {
          this.processSuccess('Рецепт удален');
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при удалении рецепта');
        });
    },

    processSuccess(title) {
      this.$bus.$emit('updateRecipesIndex');
      this.$notify({
        type: 'success',
        title: title
      });
      this.close();
    }
  }
};
</script>
