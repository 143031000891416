import Vue from 'vue';
import Vuex from 'vuex';
// import _ from 'lodash';

// Object.defineProperty(Vue.prototype, '$_', { value: _ });
import auth from '@/store/modules/auth';
import app from '@/store/modules/app.js';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    app
  },
  plugins: [
    // createPersistedState({
    //   paths: ['app.appMenu', 'app.staffProfile', 'app.ticketFilters', 'app.salaryFilters']
    // })
  ]
});
