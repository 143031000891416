<template lang="pug">
  div(id='create-tree-config')
    v-dialog(v-model="visible" width="20%" persistent)
      v-card(tile)
        v-toolbar(flat)
          v-toolbar-title Товар
          v-spacer
          v-btn(icon text @click="visible = false")
            v-icon.accent--text mdi-close

        v-card-text

          v-text-field(
            class="mb-4"
            v-model="site.title"
            placeholder="Название"
            outlined
            hide-details
            :error-messages="formErrors.title"
          )

          v-text-field(
            class="mb-4"
            v-model="site.url"
            placeholder="url"
            outlined
            hide-details
            :error-messages="formErrors.url"
          )

          v-textarea(
            class="mb-4"
            v-model="site.comment"
            placeholder="Комментарий"
            outlined
            hide-details
            :error-messages="formErrors.comment"
          )

          v-checkbox(
            class="mr-3"
            color="accent"
            label="Загружать страницы с этого сайта?"
            hide-details
            v-model="site.is_active"
            true-value=""
            false-value=0
          )

        v-card-actions
          v-btn.accent(@click="save" depressed) Сохранить
        pre {{site}}
</template>
<script>
import nocApi from '@/api/api';

export default {
  data() {
    return {
      site: {},
      formErrors: {},
      visible: false
    };
  },

  methods: {
    open(site = {}) {
      this.site = site;
      this.visible = true;
    },

    close() {
      this.formErrors = {};
      this.visible = false;
    },

    save() {
      this.formErrors = {};
      if (!this.site.id) {
        this.create();
      } else {
        this.update();
      }
    },

    update() {
      nocApi
        .put(`/sites/${this.site.id}`, this.site)
        .then(() => {
          this.processSuccess('Сайт сохранен');
          this.close();
        })
        .catch((error) => {
          this.processError(error, 'Ошибка сохранения сайта');
        });
    },

    create() {
      nocApi
        .post('/sites/create', this.site)
        .then(() => {
          this.processSuccess('Сайт добавлен');
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при добавлении сайта');
        });
    },

    processSuccess(title) {
      this.$bus.$emit('siteChanged');
      this.$notify({
        type: 'success',
        title: title
      });
      this.close();
    }
  }
};
</script>
