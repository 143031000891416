<template lang="pug">
  div
    v-toolbar.page-bar
      v-spacer
        v-btn.accent(@click ="$refs.PageCreate.open()" depressed) Добавить рецепт
    table.table.hovered.striped
      tr
        th
        th
        th Рецепт
        th url
        th

      template(v-for="recipe in recipes")
        tr
          td.actions
            v-icon(v-if="recipe.is_published") mdi-check
            v-icon(v-else) mdi-close

          td.actions

            v-btn(@click ="$refs.RecipeUpdate.open(recipe.id)" icon)
              v-icon mdi-playlist-edit

          td
            div {{recipe.title}}
            div.caption {{recipe.description}}

          td {{recipe.url}}

          td.actions {{recipe.id}}
          td.actions
            v-btn(@click ="$refs.RecipeDelete.open(recipe.id)" icon)
              v-icon.error--text mdi-trash-can-outline


    recipe-update(ref="RecipeUpdate")
    recipe-delete(ref="RecipeDelete")
    page-create(ref="PageCreate")


</template>
<script>
/**
 * @typedef    {Object}     recipe
 * @property   {Boolean}    recipe.is_published
 *
 */

import api from '@/api/api';
import RecipeUpdate from './dialogs/RecipeUpdate';
import RecipeDelete from './dialogs/RecipeDelete';
import PageCreate from '@/components/Sources/Pages/dialogs/PageCreate';

export default {
  data() {
    return {
      recipes: []
    };
  },

  components: {
    RecipeUpdate,
    RecipeDelete,
    PageCreate
  },

  created() {
    this.getRecipes();

    this.$bus.$on('updateRecipesIndex', () => {
      this.getRecipes();
    });
  },

  beforeDestroy() {
    this.$bus.$off('updateRecipesIndex');
  },

  methods: {
    getRecipes() {
      api
        .get('/recipes', {
          params: {
            sort: '-id'
          }
        })
        .then((response) => {
          this.recipes = response.data;
        })
        .catch((e) => {
          console.log('error', e);
        });
    }
  }
};
</script>
