import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import ru from 'vuetify/es5/locale/ru';

Vue.use(Vuetify);

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: colors.brown.lighten4,
        lighten: colors.brown.lighten5,
        darken: colors.brown.lighten2,
        accent: colors.brown.darken3,
        error: colors.red,
        info: colors.brown,
        success: colors.green,
        warning: colors.orange,
        fieldBg: colors.white,
        fg: colors.grey.darken4,
        link: colors.grey.darken4,
        anchor: colors.grey.darken4
      }
    }
  },
  lang: {
    locales: { ru },
    current: 'ru'
  }
});
