<template lang="pug">
  div(id="tab-menu")
    v-tabs(
      background-color="transparent"
    )
      template(v-for="item in menu")
        template(v-if="item.items" )
          v-menu(offset-y)
            template(v-slot:activator="{ on, attrs }")
              v-tab(
                :to="{name: item.route}"
                v-on="on"
                v-bind="attrs"
                class="fg--text"
                active-class="fg--text"
              ) {{item.title}}
            v-list.py-0
              template(v-for="item in item.items")
                v-list-item.py-1(:to="{name: item.route}") {{item.title}}
                v-divider

        template(v-else)
          v-tab(
            :to="{name: item.route}"
            class="fg--text"
            active-class="fg--text"
          ) {{item.title}}


</template>

<script>
import { mapGetters } from 'vuex';

export default {
  /**
   * @property {Object} currentRoute Текущий маршрут ($route)
   */
  props: {
    currentRoute: Object
  },

  /**
   * @property {Array} appMenu Главное меню приложения
   */
  computed: {
    ...mapGetters({
      appMenu: 'getAppMenu'
    })
  },

  /**
   * @property {Array} menu Меню модуля, соотвествующее текущему маршруту
   * (второй уровень массива appMenu)
   */
  data() {
    return {
      menu: []
    };
  },

  watch: {
    /**
     * Если изменился текущий маршрут, то обновляем меню модуля
     */
    currentRoute(val) {
      this.menu = this.updateModuleMenu(val);
    },
    /**
     * Если изменилось меню приложения, то обновляем меню модуля
     */
    appMenu() {
      this.menu = this.updateModuleMenu(this.currentRoute);
    }
  },

  created() {
    this.menu = this.updateModuleMenu(this.currentRoute);
  },

  methods: {
    /**
     * Возвращает массив элементов меню модуля (второй уровень appMenu)
     * на основании текущего маршрута
     *
     * @param {Object} currentRoute Текущий маршрут
     * @returns {Array}
     */
    updateModuleMenu(currentRoute) {
      let parentPath = '/' + currentRoute.path.split('/')[1];
      let menuTree = this.appMenu.find((obj) => obj.path.match(parentPath));
      if (menuTree && menuTree) {
        return menuTree.items;
      }
      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
#tab-menu {
  width: 100%;
}

.v-list-item {
  cursor: pointer;
}
</style>
