<template lang="pug">
  v-app(id="inspire")
    notifications(
      position="bottom right"
      :duration="Number(8000)"
      closeOnClick=true
      width="20%"
    )
      template(slot="body" slot-scope="props")
        v-sheet(:class="`notify type-${props.item.type}`" tile )
          v-toolbar.transparent(flat)
            div {{props.item.title}}
            v-spacer
            v-btn(icon)
              v-icon mdi-close
          v-divider
          template(v-if="props.item.text")
            template(v-if="typeof(props.item.text) === 'object'")
              template(v-for="item in props.item.text")
                div.item {{item}}
                v-divider
            div.text(v-else v-html="props.item.text")

    v-navigation-drawer(
      v-if="this.$store.getters.isAuthenticated"
      class="sidebar darken"
      width="180"
      mobile-breakpoint="1400"
      v-model="drawer"
      fixed
      app
    )
      the-side-menu

    v-app-bar(
      app
      fixed
      flat
      height="40"
      extension-height="40"
      class="accent white--text"
    )
      v-app-bar-nav-icon.white--text(@click.stop="drawer = !drawer")
      v-spacer
      div.white--text(v-if="user") {{user.login}}

      v-btn(@click="logout" v-if="isAuthenticated" text icon)
        v-icon(class="white--text") mdi-logout-variant

      template(v-if="isAuthenticated" v-slot:extension="")
        the-tab-menu(:currentRoute="$route")

    v-main.grey.lighten-5
      v-container.pa-0(fluid full-height)
        router-view(:key="$route.fullPath")
</template>

<script>
import TheSideMenu from './components/TheSideMenu';
import TheTabMenu from './components/TheTabMenu';
import { mapGetters } from 'vuex';

export default {
  /**
   * @property TheTabMenu         Меню модуля
   * @property TheSideMenu        Боковое меню
   *
   */
  components: {
    TheTabMenu,
    TheSideMenu
  },

  /**
   * @property {Boolean} isAuthenticated   Авторизован?
   * @property {Object} staffProfile       Профиль залогинившегося сотрудника
   */
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      user: 'getUserProfile'
    })
  },

  /**
   * @property {?boolean} drawer Состояние бокового меню
   *
   */
  data: () => ({
    drawer: null
  }),
  props: {
    source: String
  },
  created() {
    console.log('route', this.$route);
    if (this.isAuthenticated) {
      this.$store.dispatch('loadAppConfig');
      this.$store.dispatch('loadAppMenu');
    }
  },

  methods: {
    /**
     * Logout
     */
    logout: function () {
      this.$store.dispatch('authLogout').then(() => {
        this.$router.push('/login').catch((err) => {
          console.log('error', err);
        });
      });
    }
  }
};
</script>
<style lang="scss">
@import './sass/app.scss';
</style>
