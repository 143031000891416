import Vue from 'vue';
import { store } from '@/store';
import VueRouter from 'vue-router';
import Home from '../components/Home.vue';
import Login from '../components/Login';

import Sources from '../components/Sources';
import SourceSites from '../components/Sources/Sites/Sites';
import SourcePages from '../components/Sources/Pages/Pages';

import Recipes from '../components/Recipes';
import RecipesIndex from '../components/Recipes/Recipes';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/login');
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/source',
    name: 'Sources',
    component: Sources,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'sites',
        name: 'SourceSites',
        component: SourceSites,
        beforeEnter: ifAuthenticated
      },
      {
        path: 'pages',
        name: 'SourcePages',
        component: SourcePages,
        beforeEnter: ifAuthenticated
      }
    ]
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: Recipes,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'list',
        name: 'RecipesIndex',
        component: RecipesIndex,
        beforeEnter: ifAuthenticated
      }
    ]
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

export default router;
