<template lang="pug">
  div(id='recipe-form')
    v-dialog(v-model="visible" width="80%" persistent scrollable)
      v-card.white(tile)
        v-toolbar.white(flat)
          v-toolbar-title Рецепт
          v-spacer
          v-btn(icon text @click="visible = false")
            v-icon.accent--text mdi-close
        v-toolbar
          v-checkbox.mr-4(
            color="accent"
            label="Показывать изображения"
            hide-details
            v-model="showImages"
            true-value=true
            false-value=false
          )
          v-spacer

          v-checkbox.mr-4(
            color="accent"
            label="Опубликован"
            hide-details
            v-model="recipe.is_published"
            true-value=1
            false-value=0
          )
          v-btn.accent.mr-4(@click="save" depressed) Сохранить

        v-card-text.white
          v-row
            v-col(sm="6")
              v-card.mb-4(flat dense outlined)
                v-toolbar(flat dense)
                  v-toolbar-title Основная информация
                  v-spacer
                  v-btn.accent(@click="translateRecipe" depressed small) Перевести
                    v-icon(right) mdi-arrow-right
                v-card-text
                  v-text-field(
                    class="mb-4"
                    v-model="recipe.title"
                    placeholder="Название"
                    outlined
                    hide-details
                    :error-messages="formErrors.title"
                  )

                  v-textarea(
                    class="mb-4"
                    v-model="recipe.description"
                    placeholder="Описание"
                    outlined
                    hide-details
                    :error-messages="formErrors.description"
                  )
              v-card(flat dense outlined)
                v-toolbar(flat dense)
                  v-toolbar-title Ингредиенты
                v-card-text
                  template(v-for="(ingredient, index) in recipe.ingredients")
                    div.d-flex
                      v-text-field.mr-1(
                        v-model="ingredient.amount_us"
                        placeholder="US"
                        outlined
                        hide-details
                      )

                      v-text-field.mr-1(
                        class="ingredient-text"
                        v-model="ingredient.text"
                        placeholder="Название"
                        outlined
                        hide-details
                      )

                      v-text-field.mr-1(
                        v-model="ingredient.amount_eu"
                        placeholder="EU"
                        outlined
                        hide-details
                      )
                      v-btn(@click="deleteIngredient(index)" icon)
                        v-icon mdi-trash-can-outline

                v-btn.primary(@click="addIngredient()" small depressed)
                  v-icon(left) mdi-plus
                  span Ингредиент

            v-col(sm="6")
              template(v-if="recipe.english")
                v-card.mb-4(flat dense outlined)
                  v-toolbar(flat dense)
                    v-toolbar-title Main information
                  v-card-text
                    v-text-field(
                      class="mb-4"
                      v-model="recipe.english.title"
                      placeholder="Название"
                      outlined
                      hide-details
                      :error-messages="formErrors.title"
                    )

                    v-textarea(
                      class="mb-4"
                      v-model="recipe.english.description"
                      placeholder="Описание"
                      outlined
                      hide-details
                      :error-messages="formErrors.description"
                    )
                v-card(flat dense outlined)
                  v-toolbar(flat dense)
                    v-toolbar-title Ingredients
                  v-card-text
                    template(v-for="(ingredient, index) in recipe.ingredients")
                      div.d-flex
                        v-text-field.mr-1(
                          v-model="ingredient.amount_us"
                          placeholder="US"
                          outlined
                          hide-details
                        )

                        v-text-field.mr-1(
                          class="ingredient-text"
                          v-model="ingredient.text"
                          placeholder="Название"
                          outlined
                          hide-details
                        )

                        v-text-field.mr-1(
                          v-model="ingredient.amount_eu"
                          placeholder="EU"
                          outlined
                          hide-details
                        )
                        v-btn(@click="deleteIngredient(index)" icon)
                          v-icon mdi-trash-can-outline

                  v-btn.primary(@click="addIngredient()" small depressed)
                    v-icon(left) mdi-plus
                    span Ингредиент


          v-row
            template(v-for="(instruction, index) in recipe.instructions")

              v-col(sm="6")
                v-card(flat dense outlined)
                  v-toolbar(flat dense)
                    v-toolbar-title Шаг {{index + 1 }}
                    v-spacer
                    v-btn(@click="deleteInstruction(index)" icon)
                      v-icon mdi-trash-can-outline
                    v-btn.accent(@click="translateInstruction(instruction)" depressed small) Перевести
                      v-icon(right) mdi-arrow-right

                  v-img(
                    v-show="showImages"
                    :src="appParams.recipeImagesUrl + recipe.id + '/' + instruction.image"
                  )

                  div.d-flex
                    v-textarea(
                      v-model="instruction.text"
                      placeholder="Название"
                      outlined
                      hide-details
                    )

              v-col(sm="6")
                template(v-if="instruction.english")
                  v-card(flat dense outlined)
                    v-toolbar(flat dense)
                      v-toolbar-title Шаг {{index + 1 }}
                      v-spacer
                      v-btn(@click="deleteInstruction(index)" icon)
                        v-icon mdi-trash-can-outline


                    v-img(
                      v-show="showImages"
                      :src="appParams.recipeImagesUrl + recipe.id + '/' + instruction.image"
                    )

                    div.d-flex
                      v-textarea(
                        v-model="instruction.english.text"
                        placeholder="Название"
                        outlined
                        hide-details
                      )


        v-toolbar(flat dense)
          v-btn(@click="addInstruction()" small depressed)
            v-icon(left) mdi-plus
            span Шаг рецепта

</template>
<script>
import api from '@/api/api';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      recipe: {},
      formErrors: {},
      visible: false,
      showImages: true
    };
  },

  computed: {
    ...mapGetters({
      appParams: 'getAppConfig'
    })
  },

  methods: {
    open(id = null) {
      if (id) {
        this.getRecipe(id);
      }
      this.visible = true;
    },

    close() {
      this.recipe = {};
      this.formErrors = {};
      this.visible = false;
    },

    getRecipe(id) {
      api
        .get(`/recipes/${id}`, {
          params: {
            expand: 'english,ingredients.english,instructions.english'
          }
        })
        .then((response) => {
          this.recipe = response.data;
        })
        .catch((e) => {
          console.log('error', e);
        });
    },

    save() {
      this.formErrors = {};
      if (!this.recipe.id) {
        this.create();
      } else {
        this.update();
      }
    },

    update() {
      api
        .put(`/recipes/${this.recipe.id}`, this.recipe)
        .then(() => {
          this.processSuccess('Рецепт сохранен');
          this.close();
        })
        .catch((error) => {
          this.processError(error, 'Ошибка сохранения рецепта');
        });
    },

    create() {
      api
        .post('/recipes/create', this.recipe)
        .then(() => {
          this.processSuccess(' Рецепт добавлен');
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при добавлении рецепта');
        });
    },

    processSuccess(title) {
      this.$bus.$emit('updateRecipesIndex');
      this.$notify({
        type: 'success',
        title: title
      });
      this.close();
    },

    addIngredient() {
      if (!this.lodash.isEmpty([...this.recipe.ingredients].pop())) {
        this.recipe.ingredients.push({});
      }
    },
    addInstruction() {
      if (!this.lodash.isEmpty([...this.recipe.instructions].pop())) {
        this.recipe.instructions.push({});
      }
    },

    deleteIngredient(index) {
      if (this.recipe.ingredients[index].id) {
        api
          .delete(`/ingredients/${this.recipe.ingredients[index].id}`)
          .then(() => {
            this.recipe.ingredients.splice(index, 1);
          })
          .catch((error) => {
            this.processError(error, 'Ошибка удаления ингредиента');
          });
      } else {
        this.recipe.ingredients.splice(index, 1);
      }
    },

    deleteInstruction(index) {
      if (this.recipe.instructions[index].id) {
        api
          .delete(`/instructions/${this.recipe.instructions[index].id}`)
          .then(() => {
            this.recipe.instructions.splice(index, 1);
          })
          .catch((error) => {
            this.processError(error, 'Ошибка удаления шага приготовления');
          });
      } else {
        this.recipe.instructions.splice(index, 1);
      }
    },

    getInstructionImageName(recipe) {
      return '/images/recipes/' + recipe.id + '/' + recipe.image;
    },

    translateRecipe() {
      api
        .post('/translate/recipe', this.recipe)
        .then(() => {
          this.getRecipe(this.recipe.id);
          this.$notify({
            type: 'success',
            title: 'Перевод завершен'
          });
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при переводе текста');
        });
    },

    translateInstruction(instruction) {
      api
        .post('/translate/instruction', instruction)
        .then(() => {
          this.getRecipe(this.recipe.id);
          this.$notify({
            type: 'success',
            title: 'Перевод завершен'
          });
        })
        .catch((error) => {
          this.processError(error, 'Ошибка при переводе текста');
        });
    }

    // translateTitle: async () => {
    //   this.recipe.english = {
    //     title: await api.post('/translate/to-english', this.recipe.title)
    //   };
    //   console.log('translateData', this.recipe.english);
    // },
    //
    // translate: async (text) => {
    //   let { data } = await api.post('/translate/to-english', { text });
    //   return data;
    // },
    //
    // translateXX(text) {
    //   api
    //     .post('/translate/to-english', { text })
    //     .then((response) => {
    //       this.recipe.english.title = response.data.title;
    //       this.recipe.english.description = response.data.description;
    //       this.$notify({
    //         type: 'success',
    //         title: 'Перевод завершен'
    //       });
    //       console.log('translate', response.data);
    //     })
    //     .catch((error) => {
    //       this.processError(error, 'Ошибка при переводе текста');
    //     });
    // }
  }
};
</script>

<style lang="scss" scoped>
.ingredient-text {
  width: 70%;
}
</style>
